<style lang="scss" scoped>
</style>
<template>
  <el-dialog title="新增分類" v-model="state.dialogFormVisible" width="800px">
    <el-form
      :model="state.form"
      :ref="(el) => (state.addFrom = el)"
      :rules="state.addRules"
    >
      <div class="flex_line_c">
        <el-row class="width_100">
          <el-col :span="24">
            <el-form-item
              label="分類名稱"
              :label-width="state.formLabelWidth"
              prop="name"
              class="width_100"
            >
              <el-input
                v-model="state.form.name"
                autocomplete="off"
                placeholder="請輸入分類名稱"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="狀態"
              prop="status"
              class="width_100"
              :label-width="state.formLabelWidth"
            >
              <el-select placeholder="請選擇" v-model="state.form.region">
                <el-option label="正常" value="1"></el-option>
                <el-option label="禁用" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="state.dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="add_submit">確 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { reactive, watch, getCurrentInstance } from "vue";

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      default: "",
    },
  },
  emits: {
    toggleDialogVisible(data) {
      return data;
    },
    reloadData(data) {
      return data;
    },
  },
  setup(props, { emit }) {
    // 变量

    // 表单验证

    // state
    const state = reactive({
      radio: "1", // 選擇分類單選
      dialogFormVisible: props.dialogVisible,
      form: {
        // 表单数据
        name: props.name,
        region: "1",
      },
      addFrom: "", // 表单元素
      formLabelWidth: "80px", // label的宽度
      addRules: {
        // 表单正则
        name: [{ required: true, message: "請輸入分類名稱", trigger: "blur" }],
      },
    });

    // 监听
    watch(
      () => props.dialogVisible,
      (val, old) => {
        if (val) state.dialogFormVisible = val;
      }
    );

    watch(
      () => state.dialogFormVisible,
      (val, old) => {
        if (!val) emit("toggleDialogVisible", true);
      }
    );

    // 生命周期
    // created
    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $message, $http } = proxy;

    // methods

    const add_submit = () => {
      // 点击确定按钮
      state.addFrom.validate(async (valid) => {
        if (!valid) return;
        // console.log(state.form); // 新增拿到的数据
        // const res = await $http.updatePass({
        //   password: state.form.pass,
        //   id: props.userId,
        // });
        // if (res.status !== 200) return;
        $message.success("新增成功");
        emit("reloadData", state.form);
        state.dialogFormVisible = false;
      });
    };

    return { state, add_submit, props };
  },
};
</script>
